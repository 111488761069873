import React, { Component } from 'react'
import { navigate, graphql } from 'gatsby'
import get from 'lodash/get'
import PageLayout from '../components/layouts/PageLayout'
import lambdaAPI from '../lambdaAPI'
import paperformAPI from '../paperformAPI'
import { SearchHelper } from '../helpers'
import EventTracker from './../helpers/EventTracker'
import {SECTIONS} from './../utils/url'
import {CONSTANT} from '../variables';
import './../assets/styles/app.scss'

export default class PaperformPaymentProcessing extends Component {
    constructor(props) {
        super(props)

        const queryParams = SearchHelper.queryParamsToObject(this.props.location.search)
        this.paymentId = queryParams['?id']
        this.donationType = queryParams.donationType
        this.submissionId = queryParams.submissionId
    }

    componentDidMount() {
        // This should not happen but it does when doing npm run build
        if(!this.paymentId) {
            return
        }
        
        this.storeResponseInLocalStorage.call(this, this.submissionId)
        this.getStatus.call(this, this.paymentId, this.donationType, this.submissionId)
    }
    storeResponseInLocalStorage = (submissionId) => {
        paperformAPI.getResponseBySubmissionId(submissionId).then(res => {
            if(res.results.submission.data['bi8o5'] === 'Yes'){
                localStorage.setItem(CONSTANT.RESPONSE_KEYS, JSON.stringify(res.results.submission.data))
            }
                
        }).catch(err => {
            console.error(err)
        })
    }
    getStatus = (paymentId, donationType,  submissionId) => {
        lambdaAPI.getPaymentStatus(paymentId, donationType, submissionId)
        .then(res => {
            if(res.result.status !== 'complete' && res.result.errorCode == null) {
                setTimeout(() => this.getStatus(paymentId, donationType, submissionId), 1000)
                return;
            }

            if(res.result.errorCode != null) {
                throw res.result.errorMessage
            }

            EventTracker.ready().then(() => {
                EventTracker.track({
                    eventType: res.frequency === 'single' ? EventTracker.EVENT_TYPES.ONE_OFF_DONATION : EventTracker.EVENT_TYPES.RECURRING_DONATION,
                    amount: res.amount,
                    frequency: res.frequency,
                })    
            })
            navigate(`${SECTIONS.SUPPORT_STARSHIP.url}/thank-you`, { replace: true })
        })
        .catch(error => {
            console.log(error)
            navigate(`${SECTIONS.SUPPORT_STARSHIP.url}/new-payment-failed`, { replace: true, state: {message: error} })
        })
    }

    render() {
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <PageLayout className={`app-root processing-payment`} sitemap={sitemap} >
                <main className={`wrapper full-page`}>
                    <h2>Processing Payment</h2>
                    <p>We are processing your request. Please wait. Don't close the browser or refresh the page.</p>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
query getSiteMapDonationProcessingNew {
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`