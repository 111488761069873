import axios from 'axios';

const url = process.env.GATSBY_PAPERFORM_API;

async function getResponseBySubmissionId(submissionId) {
    const submission = await axios({
        method: "get",
        url: `${url}?submissionId=${submissionId}`,
    });
    return submission.data;
}

const paperformAPI = {
    getResponseBySubmissionId
}

export default paperformAPI;